import type { LANGUAGE } from '$lib/constants/locale';

export const getPopularDestinationRoute = (lang: LANGUAGE, destination: string) => {
	const patterns = {
		en: 'from-palma-airport-to-$DESTINATION',
		es: 'desde-aeropuerto-palma-a-$DESTINATION',
		de: 'von-flughafen-palma-nach-$DESTINATION'
	};
	const pattern = patterns[lang];
	return pattern.replace('$DESTINATION', destination);
};

export const extractDestination = (path: string) => {
	const patterns = {
		english: /^from-palma-airport-to(.+)$/,
		spanish: /^desde-aeropuerto-palma-a(.+)$/,
		german: /^von-flughafen-palma-nach(.+)$/
	};
	for (const pattern of Object.values(patterns)) {
		const match = path.match(pattern);
		if (match) {
			return match[1].trim().replace('-', '');
		}
	}
	return path;
};

export const isPopularDestinationRoute = (path: string) => {
	const patterns = {
		english: /^from-palma-airport-to(.+)$/,
		spanish: /^desde-aeropuerto-palma-a(.+)$/,
		german: /^von-flughafen-palma-nach(.+)$/
	};
	for (const pattern of Object.values(patterns)) {
		if (pattern.test(path)) {
			return true;
		}
	}
	return false;
};