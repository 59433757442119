import { defaultLang, type LANGUAGE } from '$lib/constants/locale';
import {
	extractDestination,
	getPopularDestinationRoute,
	isPopularDestinationRoute
} from './popularDestination';

export const LOCALIZELINK = (
	langCode: LANGUAGE = defaultLang,
	routeCode: string,
	pageID?: string
): string => {
	if (routeCode) {
		if (isPopularDestinationRoute(routeCode)) {
			return `${LOCALIZELINK(langCode, LINK_BOOKING)}/${getPopularDestinationRoute(
				langCode,
				extractDestination(routeCode)
			)}`;
		}
		if (LOCALIZED_URL_OBJECT[routeCode as keyof typeof LOCALIZED_URL_OBJECT]) {
			const localizedRouteObj =
				LOCALIZED_URL_OBJECT[routeCode as keyof typeof LOCALIZED_URL_OBJECT];
			if (localizedRouteObj[langCode]) {
				if (localizedRouteObj[langCode] == 'ROOT') {
					return `/${langCode}`;
				}
				if (pageID) {
					return `/${langCode}/${localizedRouteObj[langCode]}/${pageID}`;
				}
				return `/${langCode}/${localizedRouteObj[langCode]}`;
			}
		} else {
			return `/${langCode}/${routeCode}`;
		}
	}
	return '';
};
export const LINK_ABOUT = 'about-us';
export const LINK_CONTACT = 'contact';
export const LINK_BOOKING = 'booking';
export const LINK_FAQS = 'faqs';
export const LINK_PRIVACY_POLICY = 'privacy-policy';
export const LINK_TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const LINK_DESTINATIONS = 'destinations';
export const LINK_SERVICES = 'services';
export const LINK_BLOG = 'blog';
export const LINK_ROOT = '/';
export const LINK_REVIEW = 'review';

export const LOCALIZED_URL_OBJECT = {
	[LINK_ABOUT]: {
		en: 'about-us',
		es: 'sobre-nosotros',
		de: 'uber-uns'
	},
	[LINK_CONTACT]: {
		en: 'contact',
		es: 'contacto',
		de: 'kontakt'
	},
	[LINK_BOOKING]: {
		en: 'booking',
		es: 'reserva',
		de: 'buchung'
	},
	[LINK_FAQS]: {
		en: 'faqs',
		es: 'faqs',
		de: 'faqs'
	},
	[LINK_PRIVACY_POLICY]: {
		en: 'privacy-policy',
		es: 'politica-de-privacidad',
		de: 'datenschutzrichtlinie'
	},
	[LINK_TERMS_AND_CONDITIONS]: {
		en: 'terms-and-conditions',
		es: 'terminos-y-condiciones',
		de: 'terms-and-bedingungen'
	},
	[LINK_DESTINATIONS]: {
		en: 'destinations',
		es: 'destinos',
		de: 'reiseziele'
	},
	[LINK_SERVICES]: {
		en: 'services',
		es: 'servicios',
		de: 'dienstleistungen'
	},
	[LINK_BLOG]: {
		en: 'blog',
		es: 'blog',
		de: 'blog'
	},
	[LINK_ROOT]: {
		en: 'ROOT',
		es: 'ROOT',
		de: 'ROOT'
	},
	[LINK_REVIEW]: {
		en: 'review',
		es: 'revisar',
		de: 'rezension'
	}
};

export const getDefaultCategory = (langCode: LANGUAGE = 'en', category: string) => {
	const defaultCategory = category;
	if (langCode && category) {
		const destinations = Object.values(LOCALIZED_URL_OBJECT[LINK_DESTINATIONS]);
		if (destinations.includes(category)) {
			return LINK_DESTINATIONS;
		}
		const services = Object.values(LOCALIZED_URL_OBJECT[LINK_SERVICES]);
		if (services.includes(category)) {
			return LINK_SERVICES;
		}
		const blog = Object.values(LOCALIZED_URL_OBJECT[LINK_BLOG]);
		if (blog.includes(category)) {
			return LINK_BLOG;
		}
	}
	return defaultCategory;
};

export const translateUrl = (
	from: 'en' | 'es' | 'de',
	to: 'en' | 'es' | 'de',
	url: string,
	localizedUrls: {
		en: string[];
		es: string[];
		de: string[];
	}
) => {
	// use index for matching
	const fromIndex = localizedUrls[from]?.indexOf(url);
	if (fromIndex === -1) {
		return url;
	}
	return localizedUrls[to][fromIndex];
};
