export const LOCALE = ['en', 'es', 'de'] as const;

export type LANGUAGE = (typeof LOCALE)[number];

export const defaultLang = LOCALE[0];

export const langEntries = () => {
	return LOCALE.map((locale) => {
		return {
			lang: locale
		};
	});
};

export const isSupportedLang = (lang: string) => {
	return LOCALE.indexOf(lang as LANGUAGE) > -1;
};

export const displayLanguages = {
	en: 'English',
	es: 'Español',
	de: 'Deutsch'
};
